<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col cols="12" md="6" class="d-flex flex-column">
          <b-card-text>{{ $t("Resim boyutu 120x280 olmalıdır") }}</b-card-text>
          <div class="bg-light-primary rounded-top text-center">
            <b-img :src="referans.sLogoUrl" height="120" width="280" />
            <b-button
              variant="outline-primary btn-image-update"
              class="mt-0 mt-md-2"
              @click="openUpload()"
            >
              <feather-icon icon="PaperclipIcon" class="mr-25" />
              <span>{{ !referans.sLogoUrl ? $t("Ekle") : $t("Değiştir") }}</span>
            </b-button>
          </div>
        </b-col>
        <b-col class="mt-2" md="6">
          <b-form-group :label="$t('Açıklama')">
            <b-form-textarea
              v-model="referans.sAciklama"
              id="sAciklama"
              :placeholder="$t('Açıklama')"
              rows="4"
              :state="referans.sAciklama.length <= 250"
            />
            <span class="small" v-if="referans.sAciklama.length > 250">{{
              $t("250 karakter aşıldı")
            }}</span>
            <b-form-checkbox
              v-model="referans.isActive"
              class="custom-control-primary mt-2"
            >
              {{ $t("Aktif Durumu") }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <save-button :onClickSave="save" :showSaveAndClose="false" />
    </b-form>
  </b-card>
</template>
<script>
import AlertService from '@/common/alert.service'
import { inputImageRenderer } from '@core/comp-functions/forms/form-utils';
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import SaveButton from '@/components/SaveButton.vue'

export default {
  components: {
    SaveButton,
  },
  data() {
    return {
      referans: {
        id: 0,
        sLogoUrl: '',
        sAciklama: '',
        isActive: true,
      },
      form: new FormData(),
    }
  },
  methods: {
    save() {
      if (!this.referans.sLogoUrl && !this.form.get('file')) {
        AlertService.error(this, 'Lütfen bir resim ekleyiniz.')
        return;
      }
      if (this.referans.sAciklama.length > 250) return;
      this.form.set('id', this.referans.id);
      this.form.set('sAciklama', this.referans.sAciklama);
      this.form.set('isActive', this.referans.isActive);
      this.$store.dispatch('referansYonetim/saveReferans', this.form, this)
        .then(response => {
          if (response.statusCode === 200) {
            this.$toast({
              component: ToastificationSuccess,
              position: 'top-right',
              props: { text: response.message },
            })
            this.$router.go(-1)
          } else {
            AlertService.error(this, response.message)
          }
        });
    },
    openUpload() {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'
      fileInput.addEventListener('change', event => {
        this.form = new FormData();
        this.form.append('file', event.target.files[0]);
        this.form.append('folderName', 'referans-resim');
        inputImageRenderer(event, base64 => {
          this.referans.sLogoUrl = base64
        })
      })
      fileInput.click();
    },
  },
  created() {
    const id = parseInt(this.$route.params.id, 10);
    if (id !== -1) {
      this.$store.dispatch('referansYonetim/fetchReferans', id)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.sAciklama = response.result.sAciklama || '';
            this.referans = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        });
    }
  },
}
</script>

<style scoped>
.btn-image-update {
  position: absolute !important;
  bottom: 0px;
  right: 15px;
}
</style>
